import { Controller } from 'stimulus';
import { prepareEventData } from './alerts_controller';
import { FetchRequest } from '@rails/request.js';

/**
 * Handles form autosubmit on various actions, with optional integration with Alerts
 * @example Basic usage
 *   form[data-controller="autosubmit" data-autosubmit-response-kind-value="json" action="/users" method="post"]
 *    /! submits as you type
 *    input[data-action="autosubmit#submit"]
 *    /! `handler` targets become disabled until request finishes
 *    input[data-autosubmit-target="handler" data-action="autosubmit#submit"]
 *    /! `#reset` action sets form to it's initial state
 *    button[data-autosubmit-target="handler" data-action="autosubmit#reset"] Clear
 *
 * The `data-autosubmit-response-kind-value` is a required attribute to make it work,
 * it currently supports two values:
 * - json (it would look for `id` and `html` keys in the response and replace an
 *   element found by `id` with `html`, this is a legacy approach from pre-Turbo era)
 * - turbo-stream (in this case response is expected to contain turbo stream and
 *   will be handled by Turbo)
 *
 * There are two available actions: `#submit` and `#submitDebounced`. For the latter
 * to work, you have to specify `debounce` value with milliseconds of delay.
 * @example Debouncing
 *   form[data-controller="autosubmit" data-autosubmit-debounce-value="500"]
 *     /! submits when user interacts with input then waits for half a second
 *     /! or when user presses Tab - then submit will be immediate
 *     input[data-action="autosubmit#submitDebounced keydown.tab->autosubmit#sibmit"]
 *
 * @example (with json response kind) Display alert on success (if some parent element has Alerts controller)
 *   form[data-controller="autosubmit" action="/users" method="post"]
 *     template[data-autosubmit-target="alertSuccess"]
 *       div.flash Success!
 */
export default class extends Controller {
  static targets = ['handler', 'alertSuccess'];

  static values = {
    debounce: Number,
    responseKind: String,
  };

  connect() {
    if (!(this.element instanceof HTMLFormElement)) {
      throw new Error('autosubmit controller should be assigned to the form only!');
    }

    if (this.debounceValue) {
      this.submitDebounced = $.debounce(this.submit.bind(this), this.debounceValue);
    }
  }

  submit() {
    this.submitDebounced.cancel && this.submitDebounced.cancel();

    const request = this.#prepareRequest();
    // it is important to disable inputs after request was prepared,
    // as disabled inputs would not appear in FormData object
    this.#disabled = true;

    request
      .perform()
      .then((response) => {
        switch (this.responseKindValue) {
          case 'turbo-stream':
            return null;
          case 'json':
            return this.#handleJSON(response);
          default:
            throw new Error(`unable to handle response of a kind ${this.responseKindValue}`);
        }
      })
      .catch(console.error)
      .finally(() => {
        this.#disabled = false;
      });
  }

  submitDebounced() {
    throw new Error('you need to provide `debounce` value > 0 in order to use debounced function');
  }

  reset() {
    this.element.reset();
    this.submit();
  }

  set #disabled(value) {
    this.handlerTargets.forEach((element) => {
      element.disabled = value;
    });
  }

  get #url() {
    return this.element.action;
  }

  get #method() {
    return this.element.method;
  }

  #prepareRequest() {
    const request = new FetchRequest(this.#method, this.#url, {
      responseKind: this.responseKindValue,
    });

    const data = new FormData(this.element);
    if (this.#method === 'get') {
      request.options.query = data;
    } else {
      request.options.body = data;
    }

    return request;
  }

  #handleJSON(response) {
    return response.json.then(({ id, html }) => {
      const element = document.getElementById(id);
      if (!element) {
        return;
      }

      if (this.hasAlertSuccessTarget) {
        this.dispatch('success', prepareEventData(this.alertSuccessTarget));
      }

      const template = document.createElement('template');
      template.innerHTML = html;
      // if there was an alert shown, give event a time to bubble up
      setTimeout(() => element.replaceWith(template.content));
    });
  }
}
